<template>
  <div>
    <div class="row">
      <div class="col-12 mb-4">
        <h4 class="blue-text font-weight-bold">
          <font-awesome-icon
            @click="$router.go(-1)"
            class="mr-3"
            :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
          />
          <span class="details-title">請求書一覧</span>
        </h4>
      </div>
      <div class="col-12">
        <div class="row m-0" v-if=" invoiceData">
          <div class="col-12 col-md-6 col-lg-6 cl-xl-6 mb-5">
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">請求年月:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">{{ paymentDate }}</p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">法人名:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{ companyName }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">お客様名:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{ firstName }} {{lastName}}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">合計金額:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p class="ellipsis-1">
                  {{ moneyFormat(total) }} 円
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-12 mb-4">
            <div class="details">
              <table width="100%">
                <tr>
                  <th scope="col">リクエスト番号</th>
                  <th scope="col">購入額</th>
                  <th scope="col">購入日時</th>
                </tr>
                <tr v-for="stagingData in invoiceData" :key="stagingData.staging_req_id">
                  <td>{{ stagingData.staging_req_id }}</td>
                  <td>{{ moneyFormat(stagingData.amount) }}</td>
                  <td>{{ formatDate(stagingData.updated_at) }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Services
import { adm_invoicePayment_GetDetail } from '../../services/admin/staging';

// Import Moment JS
import moment from 'moment';

//import common
import { formatDate, formatPrice } from '../../common/Utility';

// Import Vuex/Store
import { mapGetters } from 'vuex';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Invoice Payment | View',
    };
  },
  data() {
    return {
      invoiceData: null,
      firstName: null,
      lastName: null,
      paymentDate: null,
      total: 0,
      companyName: null,
    };
  },

  watch: {
    'pagination.limit'(newVal) {
      this.pagination.limit = parseInt(newVal);

      if (this.pagination.limit < 0) {
        this.pagination.limit = 0;
      }
    },
  },
  computed: {
    ...mapGetters(['currentRoute']),
  },
  mounted() {
    this.setInvoiceData();
  },

  methods: {
    formatDate,
    formatPrice,
    setInvoiceData() {
      this.getResults();
    },

    moneyFormat(data) {
      if (data == null) {
        return 0;
      }

      return Number(data).toLocaleString();
    },

    getResults() {

      let invoiceId = this.$route.params.id;
      let accessToken = localStorage.getItem('_accessToken');

      this.invoiceData = null;
      adm_invoicePayment_GetDetail({
        id: invoiceId,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          this.invoiceData = data;
          console.log(this.invoiceData);

          if (data.length > 0) {
            this.firstName = data[0]['staging_request']['user']['user_profile']['first_name'];
            this.lastName = data[0]['staging_request']['user']['user_profile']['last_name'];
            this.companyName = data[0]['staging_request']['user']['user_profile']['company_name'];
            let date = moment(data[0]['created_at'], "YYYY/MM");
            this.paymentDate = date.format('YYYY/MM');
          }

          for (let i = 0; i < data.length; i++) {
            this.total += Number(data[i]['amount']);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$bvToast.toast(
            'データの取得中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );
        });

      //scroll view on top
      var detailsWrapper = this.$refs['details-wrapper'];
      if (detailsWrapper) {
        detailsWrapper.scrollIntoView({ block: 'center', inline: 'nearest' });
      }
    },
  },
};
</script>

<style scoped>
.main-content {
  padding: 110px 60px 150px 350px;
}
.details-title {
  font-size: 19px;
  font-style: normal normal medium 19px/23px Roboto;
}
.details-title {
  margin-bottom: 43px;
}
.details span,
.details p {
  font-size: 16px;
}

@media only screen and (max-width: 991px) {
  .main-content {
    padding: 120px 35px 80px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: 120px 10px 80px 10px;
  }
}

#uploaded-image {
  margin-top: 40px;
}

#uploaded-image .image {
  height: 340px;
  background: #ececec;
  border-radius: 15px;
  padding: 0;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 1440px) {
  #uploaded-image .image {
    height: 300px;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .image {
    height: 400px;
  }
}

@media only screen and (max-width: 425px) {
  #uploaded-image .image {
    height: 250px;
  }
}

#uploaded-image .image img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
}

#uploaded-image .image .img-carousel {
  height: 100%;
  border-radius: 15px;
  background: #e9e9e9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#uploaded-image .timestamp {
  margin: 10px 0 0 20px;
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .timestamp {
    margin: 10px 0 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .details {
    margin-top: 60px;
  }
}

.download-wrapper {
  position: absolute;
  right: 36px;
  bottom: 12px;
  background: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.download-wrapper.old-version-carousel {
  right: -40px;
  bottom: -8px;
}

.image-wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
}
</style>
